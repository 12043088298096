export const faq = {
    frequently_asked_questions: "Frequently asked questions",
    question_1: "Comment puis-je travailler avec SEND ?",
    answer_1: "C'est très simple, il vous suffit de vous inscrire et vous serez contacté par l'équipe commerciale",
    question_2: "Acceptez-vous le paiement à la livraison ?",
    answer_2: "Oui, nous acceptons le paiement à la livraison ainsi que les e-paiements.",
    question_3: "Proposez-vous la livraison à domicile ?",
    answer_3: "Oui, nous proposons la livraison à domicile.",
    question_4: "Prenez-vous une commission sur le recouvrement d'argent ?",
    answer_4: "Non, nous ne prenons pas de commission sur le recouvrement d'argent.",
    question_5: "Comment transférons-nous l'argent aux vendeurs ?",
    answer_5: "Nous transférons l'argent aux vendeurs soit via un compte bancaire, soit en espèces dans notre bureau.",
    question_6: "Quel est le coût du ramassage ?",
    answer_6: "Le ramassage est gratuit.",
    question_7: "Quelles sont les heures de disponibilité ?",
    answer_7: "Nous sommes disponibles 24 heures sur 24 et 7 jours sur 7.",
    question_8: "Quel est le coût du retour ?",
    answer_8: "Le coût du retour est inclus dans le tarif initial de livraison.",
}