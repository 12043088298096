export const fields = {
    contact_us: "تواصل معنا",
    register: "التسجيل",
    tracking: "إتبع طلبك",
    search: "البحث",
    first_name: "الإسم",
    last_name: "اللقب",
    gender: "الجنس",
    email: "البريد الإلكتروني",
    homme: "ذكر",
    femme: "أنثى",
    password: "الرقم السري",
    date_of_birth: "تاريخ الإزدياد",
    phone_number: "رقم الهاتف",
    city: "الولاية",
    commune: "البلدية",
    vehicle_type: "نوع السيارة ؟",
    motorcycle: "دراجة",
    car: "سيارة",
    delivery_experience: "هل عملت (ي) في مجال التوصيل سابقا ؟",
    oui: "نعم",
    non: "لا",
    marital_status: "الحالة الإجتماعية",
    married: "متزوج (ة)",
    single: "عازب (ة)",
    divorced: "مطلق (ة)",
    children: "هل لديك أطفال ؟",
    next: "التالي",
    upload_photo: "صورة شخصية (ذات خلفية بيضاء)",
    download_photo: "تحميل الصورة",
    criminal_record: "شهادة السوابق العدلية",
    download_document: "تحميل الملف",
    front: "الواجهة الأمامية",
    back: "الواجهة الخلفية",
    previous: "العودة",
    submit: "سجل",
    company_name: "إسم المؤسسة",
    message: "الرسالة",
    rights: "كل الحقوق محفوظة",
    address: "العنوان",
    address1: "66 طريق أولاد فايت. شراقا -",
    address2: " الجزائر 16001، الجزائر",
    all_right_reserved: " Send 2024 إرسال. جميع الحقوق محفوظة."

}