export const importSheet = {
  your_google_sheet_details: "Your Google Sheet Details",
  sheet_url: "Sheet URL",
  sheet_name: "Sheet Name",
  make_sure:
    "Make sure that the sheet structure is as the one in the dashboard!",
  cancel: "Cancel",
  import: "Import sheet",
  created_successfully: "Created Successfully!",
  something_wrong: "Something went wrong!",
  fill_required_fields: "Please fill in the required fields",
};