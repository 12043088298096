export const history = {
  title: "Historique",
  total_orders: "Nombre total de commandes",
  vs_last_month: "par rapport au mois dernier",
  total_delivered: "Nombre total de commandes livrées",
  delivered: "delivered",
  delivered_desc:
    "signifie que la commande a été livrée avec succès au client.",
  pending: "pending",
  pending_desc:
    "signifie que la commande a été placée avec succès et attend que le vendeur l'accepte.",
  cancelled: "cancelled",
  cancelled_desc: "signifie que la commande a été rejetée par le client.",
  returned: "retourné",
  returned_desc: "signifie que la commande a été retournée au vendeur.",
  order_info: "Informations sur la commande",
};