export const newOrder = {
  client: 'العميل',
    client_placeholder: 'أدخل اسم العميل',
    phone: 'الهاتف',
    phone_placeholder: 'أدخل رقم الهاتف',
    address: 'عنوان التسليم',
    address_placeholder: 'أدخل عنوان التسليم',
    product: 'المنتج',
    product_placeholder: 'أدخل اسم المنتج',
    delivery_cost: 'تكلفة التوصيل',
    delivery_cost_placeholder: 'أدخل تكلفة التوصيل',
    total_cost: 'التكلفة الإجمالية',
    total_cost_placeholder: 'أدخل التكلفة الإجمالية',
    payment_type: 'طريقة الدفع',
    cash: 'نقداً',
    e_transfer: 'التحويل الإلكتروني',
    notes: 'ملاحظات',
    notes_placeholder: 'أدخل الملاحظات',
    create_order: 'إنشاء الطلب',
    edit_order: 'تعديل الطلب',
    cancel: 'إلغاء',

    //notifications
    created_successfully: 'تم الإنشاء بنجاح!',
    modified_successfully: 'تم التعديل بنجاح!',
    something_wrong: 'حدث خطأ ما!',
  };