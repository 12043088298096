export const orders = {
  title: "Orders",
  import_sheet: "Import Sheet",
  new_order: "New Order",
  order_driver: "Order Diver",
  delete: "Delete",
  cancel_delete: "Cancel",
  delete_order_msg: "Are you sure you want to permanently delete this data?",
  order_info: "Order information",
  search: "Search",
  page: "Page",
  next: "Next",
  of: "Of",
  previous: "Previous",
};