export const orderDriver = {
  order_driver: "Commande Chauffeur",
  delivery_method: "Méthode de livraison",
  car: "Voiture",
  motorcycle: "Moto",
  use_bonus: "Utiliser notre bonus",
  activate: "Activer",
  deactivate: "Désactiver",
  confirm_order: "Confirmer la commande",
  cancel: "Annuler",
  please_wait: "Veuillez patienter...",
  product_cost: "Coût du produit",
  delivery_cost: "Frais de livraison",
  delivery_cost_after_discount: "Frais de livraison après réduction",
  to_collect: "À collecter",
  delivery_estimated_time: "Heure estimée de livraison",
};
