export const fields = {
    contact_us: "Contactez-nous",
    register: "S'inscrire",
    tracking: "Tracking",
    search: "Search",
    first_name: "Prénom",
    last_name: "Nom de famille",
    gender: "sexe",
    email: "Email",
    homme: "Homme",
    femme: "Femme",
    password: "Mot de passe",
    date_of_birth: "Date de naissance",
    phone_number: "Numéro de téléphone",
    city: "Ville",
    commune: "Commune",
    vehicle_type: "Quel est votre type de véhicule ?",
    motorcycle: "Moto",
    car: "Voiture",
    delivery_experience: "Est-ce que vous avez déjà travaillé dans la livraison ?",
    oui: "Oui",
    non: "Non",
    marital_status: "Quel est votre situation familiale ?",
    married: "Marié(e)",
    single: "Célibataire",
    divorced: "Divorcé(e)",
    children: "Est-ce que vous avez des enfants ?",
    next: "Suivant",
    upload_photo: "Votre photo (Avec un fond blanc)",
    download_photo: "Télécharger la Photo",
    criminal_record: "Casier judiciaire",
    download_document: "Télécharger le document",
    front: "La Face",
    back: "L'arrière",
    previous: "Précédent",
    submit: "Envoyer",
    company_name: "Nom de la Société",
    message: "Le meesage",
    rights: "Tous droits réservés",
    address: "Address",
    address1: "66, Route de Ouled Fayet. Chéréga -",
    address2: "Alger  16001, ALGERIE",
    all_right_reserved: "© 2024 Send. All rights reserved."

}