export const orderDriver = {
  order_driver:  "طلب سائق",
  delivery_method: "طريقة التوصيل",
  car: "سيارة",
  motorcycle: "دراجة نارية",
  use_bonus: "استخدام المكافأة",
  activate: "تفعيل",
  deactivate: "تعطيل",
  confirm_order: "تأكيد الطلب",
  cancel: "إلغاء",
  please_wait: "الرجاء الانتظار...",
  product_cost: "تكلفة المنتج",
  delivery_cost: "تكلفة التوصيل",
  delivery_cost_after_discount: "تكلفة التوصيل بعد الخصم",
  to_collect: "المبلغ المطلوب",
  delivery_estimated_time: "الوقت المتوقع للتسليم",
};
