export const orderDriver = {
  order_driver: "Order Driver",
  delivery_method: "Delivery method",
  car: "Car",
  motorcycle: "Motorcycle",
  use_bonus: "Use our bonus",
  activate: "Activate",
  deactivate: "Deactivate",
  confirm_order: "Confirm Order",
  cancel: "Cancel",
  please_wait: "Please wait...",
  product_cost: "Product Cost",
  delivery_cost: "Delivery Cost",
  delivery_cost_after_discount: "Delivery Cost After Discount",
  to_collect: "To Collect",
  delivery_estimated_time: "Delivery Estimated Time",
};
