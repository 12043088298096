export const profile = {
    firstTitle: "Profil",
    secondTitle: "Informations Personnelles",
    description: "Voir vos informations personnelles et mettre à jour vos paramètres",
    cancel: "Annuler",
    save: "Enregistrer",
    upload: "Cliquez pour télécharger",
    dragAndDrop: "ou faites glisser",
    firstName: "Prénom",
    firstNamePlaceholder: "Entrez votre prénom",
    lastName: "Nom de famille",
    lastNamePlaceholder: "Entrez votre nom de famille",
    phone: "Numéro de téléphone",
    phonePlaceholder: "Entrez votre numéro de téléphone",
    shopName: "Nom de la boutique",
    shopNamePlaceholder: "Entrez le nom de votre boutique",
    email: "Email",
    emailPlaceholder: "Entrez votre email",
    referralCode: "Code de parrainage",
    referralCodePlaceholder: "Entrez un code de parrainage",
    whatsapp: "Recevoir les notifications Whatsapp",
    language: "Langue",
    support: "Contacter le support",
    edit: "Modifier le profil",
    logout: "Déconnexion",
};
