export const history = {
  title: "التاريخ",
  total_orders: "إجمالي عدد الطلبات",
  vs_last_month: "مقابل الشهر الماضي",
  total_delivered: "إجمالي عدد الطلبات المسلمة",
  delivered: "delivered",
  delivered_desc: "يعني أن الطلب تم تسليمه بنجاح إلى العميل.",
  pending: "pending",
  pending_desc: "يعني أن الطلب تم وضعه بنجاح وينتظر من البائع قبوله.",
  cancelled: "canceled",
  cancelled_desc: "يعني أن الطلب تم رفضه من قبل العميل.",
  returned: "returned",
  returned_desc: "يعني أن الطلب تم إعادته إلى البائع.",
  order_info: "معلومات الطلب",
};