export const tracking = {
  title: "تتبع الطلبية",
  search: "بحث",
  search_placeholder: " أدخل الرقم التسلسلي للطلبية",

  //order info
  shop_name: "اسم المتجر",
  phone_number: "رقم الهاتف",
  client_name: "اسم العميل",
  driver_name: "اسم السائق",
  product_name: "اسم المنتج",
  total_price: "السعر الإجمالي",
  pickup_location: "موقع الاستلام",
  address: "العنوان",
  delivery_price: "تكلفة التوصيل",
  delivery_date: "تاريخ التوصيل",
  status_delivery: "حالة التوصيل",
  status_payment: "حالة الدفع",
  pickup_address: "عنوان الاستلام",
  delivery_status: "حالة التوصيل",
  payment_status: "حالة الدفع",
  delivery_address: "عنوان التوصيل",
};