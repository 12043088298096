export const profile = {
    firstTitle: "Profile",
    secondTitle: "Personal Information",
    description: "See your personal information and update your settings",
    cancel: "Cancel",
    save: "Save",
    upload: "Click to upload",
    dragAndDrop: "or drag and drop",
    firstName: "First Name",
    firstNamePlaceholder: "Enter your first name",
    lastName: "Last Name",
    lastNamePlaceholder: "Enter your last name",
    phone: "Phone Numnber",
    phonePlaceholder: "Enter your phone number",
    shopName: "Shop Name",
    shopNamePlaceholder: "Enter your shop name",
    email: "Email",
    emailPlaceholder: "Enter your email",
    referralCode: "Referral Code",
    referralCodePlaceholder: "Enter a referral code",
    whatsapp: "Recieve Whatsapp notifications",
    language: "Language",
    support: "Contact support",
    edit: "Edit profile",
    logout: "Logout",
};