
export const profile = {
    firstTitle: "الملف الشخصي",
    secondTitle: "الملف الشخصي",
    description: "اطلع على معلوماتك الشخصية وقم بتحديث إعداداتك",
    cancel: "إلغاء",
    save: "حفظ",
    upload: "أنقر للرفع",
    dragAndDrop: "أو اسحب وأفلت هنا",
    firstName: "الاسم",
    firstNamePlaceholder: "ادخل اسمك",
    lastName: "اللقب",
    lastNamePlaceholder: "ادخل لقبك",
    phone: "رقم الهاتف",
    phonePlaceholder: "ادخل رقم هاتفك",
    shopName: "اسم المتجر",
    shopNamePlaceholder: "ادخل اسم متجرك",
    email: "البريد الإلكتروني",
    emailPlaceholder: "ادخل بريدك الإلكتروني",
    referralCode: "رمز الإحالة",
    referralCodePlaceholder: "ادخل رمز الإحالة",
    whatsapp: "استلام الإشعارات عن طريق واتساب",
    language: "اللغة",
    support: "الاتصال بالدعم",
    edit: "تعديل الملف الشخصي",
    logout: "تسجيل الخروج",
};

