export const intégration_api = {
    title: "Intégration API",
    description: "Dans le paysage dynamique du commerce électronique d'aujourd'hui, des processus simplifiés de placement des commandes sont essentiels pour répondre aux attentes des clients et garantir une efficacité opérationnelle. C'est là que notre Intégration API entre en jeu.Qu'il s'agisse d'une entreprise de livraison, d'un magasin en ligne ou d'un commerce, notre Intégration API est conçue pour répondre aux besoins spécifiques de votre secteur. Découvrez comment notre API peut vous aider :",
    how_api_helps: "Qu'il s'agisse d'une entreprise de livraison, d'un magasin en ligne ou d'un commerce, notre Intégration API est conçue pour répondre aux besoins spécifiques de votre secteur. Découvrez comment notre API peut vous aider :",
    for_delivery_companies: "Pour les Entreprises de Livraison",
    for_online_stores: "Pour les Marques ou les magasins en ligne",
    delivery_company_title: "Vous êtes une Entreprise de Livraison ?",
    delivery_company_description: "Le dernier kilomètre du processus de livraison est connu pour être la partie la plus coûteuse et la plus complexe. Livrer chaque colis à sa destination finale nécessite des ressources importantes, notamment en termes de main-d'œuvre et de temps. Voici comment nous pouvons vous aider à réduire les coûts et la complexité associés à la livraison du dernier kilomètre :",
    cost_reduction: "Réduction des Coûts Opérationnels",
    cost_reduction_description: "En faisant appel à notre service de livraison, vous évitez les investissements initiaux et les frais liés à la mise en place d'une infrastructure logistique interne. Vous pouvez ainsi réduire vos coûts opérationnels tout en bénéficiant d'un réseau de chauffeurs indépendants disponibles à tout moment et n'importe où. Nous nous engageons à offrir le meilleur prix sur le marché, vous assurant ainsi un excellent rapport qualité-prix.",
    flexible_pricing: "Flexibilité tarifaire",
    flexible_pricing_description: "C'est une situation gagnant-gagnant ! Plus vous envoyez de commandes, moins vous dépensez. Nous comprenons que chaque entreprise a des besoins uniques, Notre approche tarifaire flexible vous permet d'adapter les tarifs en fonction de votre fréquence d'envoi et du volume de colis que vous traitez régulièrement.",
    flexibility_and_availability: "Flexibilité et Disponibilité",
    flexibility_and_availability_description: "Notre service de livraison du dernier kilomètre offre une flexibilité totale et une disponibilité 24h/24, 7j/7. Qu'il s'agisse de livraisons urgentes ou planifiées, vous pouvez compter sur notre équipe de chauffeurs-livreurs qualifiés pour répondre aux besoins de vos clients à tout moment, même pendant les heures de pointe.",
    maximize_profit_margin: "Profitez dès maintenant de notre réseau de chauffeurs disponibles à tout moment et n'importe où, vous aidant à maximiser vos marges bénéficiaires tout en offrant une valeur ajoutée à vos clients grâce à des livraisons rentables et efficaces !",
    store_title: "Vous êtes une marque ou un magasin en ligne ?",
    store_description: "Vos clients attendent une expérience de livraison impeccable de votre marque ou de votre magasin en ligne. Pour répondre à leurs attentes croissantes, vous avez besoin d'un service de livraison qui allie rapidité et flexibilité. Voici notre offre :",
    buy_now_get_now: "Buy Now, Get Now",
    buy_now_get_now_description: "Offrez à vos clients une expérience d'achat exceptionnelle avec 'SEND-Now', nous nous engageons à livrer les produits à vos clients dans un délai de 60 minutes, peu importe leur emplacement à Alger. Créez une urgence pour inciter vos clients à acheter immédiatement, augmentez vos taux de conversion et capitalisez sur leur désir de satisfaction instantanée.",
    simplicity_and_automation: "Simplicité et Automatisation",
    simplicity_and_automation_description: "En intégrant notre API à votre plateforme, vous simplifiez le processus d'achat et de livraison pour vos clients. Les commandes sont automatiquement transmises à notre application chauffeur, éliminant ainsi les tâches manuelles fastidieuses et réduisant le risque d'erreurs. Permettez à vos clients de commander jusqu'à 5 produits et de ne payer les frais de livraison que pour une seule commande ! Offrez-leur une expérience de livraison rapide et pratique, renforçant ainsi leur satisfaction et leur fidélité envers votre marque. Ne manquez pas cette opportunité et contactez-nous dès maintenant !",
    permettez_a_clients: " Permettez à vos clients de commander jusqu'à 5 produits et de ne payer les frais de livraison que pour une seule commande ! Offrez-leur une expérience de livraison rapide et pratique, renforçant ainsi leur satisfaction et leur fidélité envers votre marque. Ne manquez pas cette opportunité et contactez-nous dès maintenant !",
}