export const accueil = {
    accueil: "الصفحة الرئيسية",
    un_livreur: "أطلب سائق توصيل",
    en_click: "بنقرة واحدة",
    header_description : "استمتع بتجربة توصيل مميزة وسريعة بنقرة واحدة، مع فريقنا من السائقين الجاهزين لمساندتكم في أي وقت وفي أي مكان في الجزائر.",
    suivez_votre_commande_en_temps_réel : "إتبع طلبك في الوقت الحقيقي",
    saisissez_le_numéro_de_suivi : "أدخل رقم التتبع لمتابعة طلبك",
    qui_nous_sommes: "من نحن",
    qui_nous_sommes_description: "نحن شركة جزائرية تقدم حلولًا مبتكرة في تسليم الميل الأخير. مهمتنا هي تسهيل التواصل بين الأشخاص الباحثين عن خدمة التوصيل والسائقين المتوفرين.",
    pourquoi_nous: "لماذا نحن؟",
    ultra_rapide: "خدمات فائقة السرعة",
    processus_de_livraison : "لقد قمنا بابتكار نظام توصيل جديد يسمح لنا بتوصيل طلباتكم مباشرة إلى عملائكم، دون الحاجة إلى الانتظار في مستودعاتنا. بحيث يقوم فريقنا المحترف من السائقين بجلب الطلبات مباشرةً من مستودعكم وتوصيلها بسرعة فائقة إلى عملائكم، دون الحاجة للفرز والتخزين في مستودعاتنا. يعزز هذا التحسين من سرعة عملية التسليم، ويوفر الوقت، مما يجعل تجربة التسوق لعملائكم أكثر فاعلية وراحة. نحن نسعى دائمًا لتقديم خدمة توصيل مباشرة تلبي احتياجاتكم وتمنح عملائكم تجربة تسوق مميزة وسهلة.",
    instantanée: "خدمات فورية",
    livraison_personnalisée: "تمتع بتجربة توصيل فريدة في الجزائر بنقرة واحدة! فريقنا المختص، الذي يعمل على مدار الساعة وفي جميع أرجاء الولاية، جاهز لاستلام وتوصيل طلباتك بصفة آنية. لا داعي للقلق بشأن المواعيد، حيث تلبي خدمتنا احتياجاتك العاجلة بكفاءة وبسلاسة.",
    moins_chère: "أسعار تنافسية",
    rentabilité_pour_votre_entreprise : "نحن نفهم جيدًا أهمية الربحية لشركتكم، ولذلك، نقدم أسعارًا تنافسية ومعقولة لخدماتنا. من خلال تطبيق نموذج تسعيير يعتمد على المسافة، نقدم لكم حلاً اقتصاديًا للتوصيل دون المساس بالجودة. قللوا من تكاليف التوصيل مع ضمان رضا عملائكم من خلال خدماتنا المعقولة."
}