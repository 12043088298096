export const devenir_livreur = {
    title : "إنضم الى فريقنا",
    description : "العمل كسائق توصيل شريك مع SEND يتيح لك تنظيم جدول عملك وفقًا لرغبتك وتحقيق دخل إضافي، وذلك بمساعدة الناس في استلام طلباتهم في منازلهم.",
    how_to : "كيف ؟",
    pour_rejoindre_notre: "للانضمام إلى فريقنا كشريك، لا شيء أسهل ! كل ما عليك هو اتباع الخطوات التالية :",
    download_application: "قم بتحميل تطبيق SEND Chauffeur",
    inscrivez: "وقم بتسجيل معلوماتك، سنتصل بك لاحقًا لتحديد موعد لتقديم المستندات وإجراء التدريب.",
    download_now: "حمل التطبيق الأن",
    préparez: "قم بتجهيز الوثائق المطلوبة",
    votre_candidature: "بمجرد تأكيد قبول طلبك، سيتم دعوتك إلى مكتب SEND الأقرب إليك مع عدد من الوثائق المطلوبة :",
    carte_grise: "البطاقة الرمادية (نسخة)",
    permis_conduire: "رخصة السياقة (نسخة)",
    casier_judiciaire: "شهادة السوابق العدلية",
    residence: "شهادة إقامة",
    procuration: "وكالة",
    controle_technique: "المراقبة التقنية",
    assurance: "تأمين السيارة",
    assister: "حضور التدريب",
    assisterez: "بعد تقديم الوثائق ستحضر التدريب لتتعرف على تطبيق SEND Chauffeur وتطرح كل الأسئلة التي ترغب في طرحها حول الخدمة.",
    duree_formation: "مدة التدريب : ساعتين",
    horaires: "أوقات التدريب : 8:00 صباحًا، 1:00 ظهرا",
    felicitations: "تهانينا !",
    pouvez_démarrer: "يمكنك بدء تشغيل التطبيق وبدء أول رحلة SEND الخاصة بك !",
    formulaire_pour_devenir: "إملء الإستمارة لتصبح سائق توصيل SEND",
    propriétaire_vehicule: "إذا لم تكن صاحب السيارة",
   
    luEtaccepter: "لقد قرأت وأوافق على",
    conditions_générales: "الشروط العامة",
    et: " و ",
    politique: ".سياسة الخصوصية",
    first_rent: "يمكنك بدء تشغيل التطبيق وبدء أول رحلة الخاصة بك ",

    info: "المزيد من المعلومات",


    model: "طراز السيارة",
    permis: "رخصة قيادة",
    carte: "بطاقة رمادية",
    matricule: "رقم التسجيل",
    notation: "تقييم",
    surveillance: "المراقبة",

    procuration_tooltip: "إذا لم تكن المركبة مسجلة باسمك",
}