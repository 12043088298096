export const nos_tarifs = {
    title: "الأسعار",
    description: "إكتشف طريقة تسعيرنا المبتكرة و الشفافة !",
    pricing_model: "نحن نستخدم نموذج تسعير يعتمد على قياس المسافة بين التاجر و الزبون لتوفير أسعار حقيقية تعكس بدقة تكاليف التوصيل بناءً على المسافة التي يقطعها المنتج، مما يجعله نظامًا لتسعير عادل وشفاف.",
    advantages: "إكتشف المزايا",
    send_more_spend_less: "أرسل أكثر، أنفق أقل",
    send_more_spend_less_description: "إنها صفقة مربحة ! كلما أرسلتم المزيد من الطلبات، كلما أنفقتم أقل. قولوا وداعًا لرسوم التوصيل المرتفعة.",
    cost_effectiveness: "حل إقتصادي",
    cost_effectiveness_description: "هذا يعني أن عملائنا يدفعون فقط عن المسافة الفعلية التي يقطعها المنتج، مما يجعلها حلاً اقتصاديًا.",
    personalized_rates: "أسعار مرنة",
    personalized_rates_description: "نحن نقدم تسعيرًا قابلًا للتعديل بناءً على حجم إرسالك، مما يجعل أسعارنا متكيفة مع احتياجاتك.",
};
