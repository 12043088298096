export const tracking = {
  title: "Tracking",
  search: "Search",
  search_placeholder: "Enter tracking number",

  //order info
  shop_name: "Shop name",
  phone_number: "Phone Number",
  client_name: "Client Name",
  driver_name: "Driver name",
  product_name: "Product Name",
  total_price: "Total Price",
  pickup_location: "Pickup Location",
  address: "Address",
  delivery_price: "Delivery Price",
  delivery_date: "Delivery Date",
  status_delivery: "Delivery Status",
  status_payment: "Payment Status",
  pickup_address: "Pickup Address",
  delivery_status: "Delivery Status",
  payment_status: "Payment Status",
  delivery_address: "Delivery Address",
};