// import './assets/main.css'
import "./index.css";

import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { Quasar, Notify } from "quasar";
//import .env variables
export const apiUrl = import.meta.env.VITE_API_URL;

import "@quasar/extras/material-icons/material-icons.css";

// Import Quasar css
import "quasar/src/css/index.sass";

import App from "./App.vue";
import router from "./router";

// AOS Animation 
import AOS from 'aos'
import 'aos/dist/aos.css'

// I18n Translation
import i18n from "./I18n"

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);
AOS.init()

app.use(i18n);
app.use(pinia);
app.use(router);
app.use(Quasar, {
  plugins: {
    Notify,
  },
  config: {
    notify: {
      /* look at QuasarConfOptions from the API card */
    },
  },
});

router.isReady().then(() => {
  app.mount("#app");
});