export const devenir_livreur = {
    title : "Devenez Livreur Partenaire",
    description : "Travailler en tant que livreur partenaire avec SEND vous permet d’aménager votre emploi du temps à votre convenance, de gagner un revenu supplémentaire et cela en aidant les gens à recevoir leurs articles chez eux.",
    how_to : "Comment ?",
    pour_rejoindre_notre: "Pour rejoindre notre équipe de livreurs partenaires, rien de plus simple ! Il suffit de  suivre les étapes suivantes :",
    download_application: "Téléchargez l’application SEND Chauffeur",
    inscrivez: "Et inscrivez vos informations, nous vous contacterons par la suite pour fixer un rendez-vous pour le dépôt du dossier et effectuer la formation.",
    download_now: "Télécharger maintenant",
    préparez: "Préparez les documents à fournir",
    votre_candidature: "Une fois votre candidature validée, vous serez convié au bureau SEND le plus proche de chez vous avec un nombre de documents à fournir :",
    carte_grise: "Carte Grise ( copie )",
    permis_conduire: "Permis de conduire ( copie )",
    casier_judiciaire: "Casier judiciaire",
    residence: "Résidence",
    procuration: "Procuration",
    controle_technique: "Contrôle technique",
    assurance: "Assurance du véhicule",
    assister: "Assister à la formation",
    assisterez: "Vous assisterez ensuite à la formation pour vous familiariser avec l’application SEND Chauffeur et poser toutes les questions que vous voulez concernant le service.",
    duree_formation: "Durée de la formation: 2 heures",
    horaires: "Horaires pour la formation : 8h00, 13h",
    felicitations: "FÉLICITATIONS !",
    pouvez_démarrer: "Vous pouvez démarrer l’application et commencer votre première course SEND !",
    formulaire_pour_devenir: "Remplir le formulaire pour devenir livreur SEND",
    propriétaire_vehicule: "Si vous n’êtes pas le propriétaire du véhicule",
    luEtaccepter: "J'ai lu et j'accepte.",
    conditions_générales: " les conditions générales.",
    et: " et",
    politique: " la politique de confidentialité",
    first_rent: "votre première course",
    info: "Plus d'information",
    model: "Modèle de voiture",
    permis: "Permis de conduire",
    carte: "Carte grise",
    matricule: "Matricule",
    notation: "Notation",
    surveillance: "Surveillance",


    procuration_tooltip: "Si le véhicule n'est pas enregistré à votre nom",



}

