export const nos_services = {
    accueil: "الخدمات",
    un_livreur: "بصفتك تاجر، هل لديك طرود لتوصيلها في الجزائر وضواحيها ؟",
    service_description: "جرب خدمة SEND eCom التي تمنحك فرصة طلب سائق يصل إلى بابك في أي وقت لتوصيل طلبياتك إلى عملائك في وقت قياسي. نقدم هذه الخدمة بأسعار تنافسية جداً، مما يتيح لك تحقيق توفير مالي مع الاستمتاع بخدمتنا السريعة والعالية الجودة. اختر الكفاءة والسرعة بتكلفة أقل معنا !",
    advantages: "إكتشف عروضنا",
    delivery_motorcycle: "التوصيل بالدراجة",
    delivery_car: "التوصيل بالسيارة",
    pickup_price: "تكلفة تجميع الطرود (الرمساج)",
    pickup_price_value: "00 دج",
    money_collection: "عمولة استرجاع الأموال",
    delivery_mode: "طريقة التوصيل",
    delivery_mode_instant: "توصيل فوري",
    delivery_time: "مدة التوصيل",
    delivery_time_60: "60 دقيقة",
    delivery_time_90: "90 دقيقة",
    delivery_type: "نوع التوصيل",
    delivery_to_home: "إلى باب المنزل",
    cash_on_delivery: "الدفع عند الإستلام",
    cash_on_delivery_available: "متوفر",
    availability: "أوقات التوافر",
    availability_24_7: "متوفر على مدار 24 ساعة / 7 أيام في الأسبوع",
    category: "الفئة المستهدفة",
    category_small_packages: "للطرود الخفيفة وصغيرة الحجم",
    category_large_packages: "للطرود الأكبر حجمًا والتي تتطلب استخدام سيارة",
    unique_features: "استكشف ميزاتنا الفريدة التي تساعدك على تبسيط تجربتك في التوصيل وتعزيز كفاءتك :",
    google_sheets_integration: "قم بتعزيز كفاءتك من خلال ربط Google Sheets",
    google_sheets_integration_description: "وفّر الوقت وقم بتحسين كفاءتك من خلال حلول أتمتة الطلبات لدينا. بتكاملها مع Google Sheets، تقوم منصتنا \"SEND\" بجلب البيانات تلقائياً، مما يبسط إدارتك ويوفر لك رؤية فورية لطلباتك. تخلص من المهام اليدوية وقم بتحسين أدائك الآن!",
    whatsapp_integration: "تجربة توصيل مبسطة مع تكامل واتساب !",
    whatsapp_integration_description: "تحصل على اشعارات في كل مرحلة من عمليات التوصيل الخاصة بك من خلال تكاملنا مع واتساب. احصل على إشعارات فورية لتأكيد الطلب، وتحديثات حول حالة التسليم، وتقديرات للأوقات المتوقعة. استمتع بتجربة تواصل سريعة وشفافة لتوفير تجربة تسليم خالية من القلق. اجعل تتبع طلباتك أمرًا سهلاً مع تكامل واتساب لدينا، جربه اليوم!",
    call_to_action: "إذا ماذا تنتظر؟ سجل الآن، اطلب سائقًا ووفر تكاليف التوصيل الخاصة بك.",
}