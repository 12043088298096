export const intégration_api = {
    title: "خدمات التكامل",
    description: "في ساحة التجارة الإلكترونية الديناميكية اليوم، يعد تبسيط عمليات طلب المنتجات أمرًا ضروريًا لتلبية توقعات العملاء وضمان الكفاءة التشغيلية. هنا تأتي أهمية تكامل واجهة برمجة التطبيقات الخاصة بنا. سواء كانت شركة توصيل أو متجرا عبر الإنترنت أو نشاطا تجاريا ، فقد تم تصميم تكامل واجهة برمجة التطبيقات لدينا لتلبية الاحتياجات المحددة لمجال عملك. اكتشف كيف يمكن أن تساعدك واجهة برمجة التطبيقات الخاصة بنا :",
    how_api_helps: "سواء كنتم من شركات التوصيل، متاجر الكترونية أو تجار تم تصميم تكامل واجهة برمجة التطبيقات (API) لدينا لتلبية الاحتياجات الخاصة في قطاعكم. اكتشفوا كيف يمكن لـ API الخاص بنا مساعدتكم :",
    for_delivery_companies: "لشركات التوصيل",
    for_online_stores: "للعلامات التجارية",
    delivery_company_title: "هل أنت صاحب شركة توصيل ؟",
    delivery_company_description: "يُعَدّ الميل الأخير في عملية التسليم من الأمور الأكثر تكلفة وتعقيدًا. توصيل كل طرد إلى وجهته النهائية يتطلب موارد كبيرة، بما في ذلك الموارد البشرية والوقت. نقدم لك حلاً لتقليل التكلفة وتبسيط التعقيد المرتبطين بتوصيل الكيلومتر الأخير :",
    cost_reduction: "تخفيض التكاليف التشغيلية :",
    cost_reduction_description: "من خلال الاعتماد على خدماتنا، تتجنب الاستثمارات الأولية والتكاليف المرتبطة بإنشاء بنية تحتية لوجستية داخلية. بفضل شبكتنا من السائقين المستقلين، يمكنك تقليل تكاليف التشغيل وضمان توفر خدمة توصيل في أي وقت وفي أي مكان. نحن ملتزمون بتقديم أفضل سعر في السوق، مما يضمن لك قيمة ممتازة مقابل تكلفة أقل.",
    flexible_pricing: "مرونة في التسعير :",
    flexible_pricing_description: "إنها صفقة تربح الجميع! كلما زادت طلبياتك، كلما قلت مصاريفك. نحن ندرك أن كل شركة لديها احتياجات فريدة. يتيح لك نهجنا التسعير المرن تعديل الأسعار وفقًا لعدد الطرود وحجم الشحنات التي تتعامل معها بانتظام.",
    flexibility_and_availability: "أوقات توفر الخدمة :",
    flexibility_and_availability_description: "تقدم خدمتنا في تسليم الميل الأخير مرونة تامة وتوفر على مدار 24 ساعة في اليوم، 7 أيام في الأسبوع. سواء كانت التسليمات ضرورية على وجه السرعة أو مخططة مسبقًا، يمكنك أن تعتمد على فريقنا المؤهل من السائقين لتلبية احتياجات عملائك في أي وقت، حتى خلال ساعات الذروة.",
    maximize_profit_margin: "استفد الآن من شبكتنا الواسعة من السائقين المتاحين في أي وقت وفي أي مكان، مما يساعدك على توسيع هوامش الربح وتقديم قيمة مضافة لعملائك من خلال خدمة توصيل فعّالة واقتصادية !",
    store_title: "هل تمتلك علامة تجارية أو متجر الإلكتروني ؟",
    store_description: "عملاؤك ينتظرون تجربة توصيل مثالية من منتجات علامتك التجارية أو متجرك عبر الإنترنت. نحن ندرك أهمية تلبية تلك التوقعات المتزايدة، ولهذا نقدم خدمة توصيل فائقة السرعة والمرونة. اكتشف عرضنا الذي يجمع بين الكفاءة والتميز في تجربة التسوق عبر الإنترنت.",
    buy_now_get_now: "أشري منا، تلحقك منا",
    buy_now_get_now_description: "قدم لعملائك تجربة تسوق لا تُنسى مع \"SEND-Now\" نلتزم بتوصيل المنتجات إلى عملائك خلال 60 دقيقة، بغض النظر عن موقعهم في الجزائر العاصمة. تميز على منافسيك من خلال إشعار عملائك بإمكانية الحصول على طلباتهم في وقت قياسي. هذه الخدمة سوف تعزز علامتك التجارية بالإضافة إلى تحسين تجربة التسوق لعملائك.",
    simplicity_and_automation: "السهولة والسلاسة",
    simplicity_and_automation_description: "من خلال دمج واجهة برمجة التطبيقات (API) الخاصة بنا في منصتك، تبسط تجربة الشراء والتسليم لعملائك. يتم نقل الطلبات تلقائيًا إلى تطبيقنا للسائقين، مما يُزيل الحاجة للتدخل اليدوي ويُقلل من فرص الخطأ. اجعل عملية التفاعل مع خدمتنا سهلة وفعّالة لك ولعملائك.",
    permettez_a_clients: "قدم لعملائك فرصة طلب ما يصل إلى 5 منتجات مع دفع رسوم التوصيل مرة واحدة فقط! امنحهم تجربة توصيل سريعة ومريحة، مع تعزيز رضاهم وولائهم لعلامتك التجارية. لا تفوت هذه الفرصة الرائعة، واتصل بنا الآن !",

}