export const faq = {
    frequently_asked_questions: "الأسئلة الشائعة",
    question_1: "كيف يمكنني العمل مع شركتكم ؟",
    answer_1: " سهل، يجب عليك التسجيل في المنصة وسيتم الاتصال بك من قبل فريق المبيعات الخاص بنا.",
    question_2: "هل تقبلون الدفع عند الإستلام ؟",
    answer_2: "نعم، نحن نقبل الدفع عند الإستلام وكذلك الدفع الإلكتروني.",
    question_3: "هل تقدمون خدمة التوصيل إلى باب المنزل ؟",
    answer_3: "نعم، نقدم خدمة التوصيل إلى باب المنزل وإلى أي مكان ترغب فيه.",
    question_4: "هل تأخذون عمولة على إسترجاع أموال الدفع عند الإستلام ؟",
    answer_4: "لا، لا نأخذ عمولة على إسترجاع أموال الدفع عند الإستلام.",
    question_5: "كيف نحول الأموال إلى البائعين ؟",
    answer_5: "نحن نحول الأموال إلى البائعين إما عبر تحويل بنكي أو نقدًا في مكتبنا.",
    question_6: "ما هي تكلفة جمع الطرود (الرمساج) ؟",
    answer_6: "جمع الطرود مجاني",
    question_7: "ما هي ساعات توفر خدماتكم ؟",
    answer_7: "خدماتنا متوفرة كل أيام الأسبوع وعلى مدار 24 ساعة في اليوم.",
    question_8: "ما هي تكلفة إرجاع الطرود الملغية ؟",
    answer_8: "تكلفة إرجاع الطرود الملغية مدمجة في السعر الأولي للتوصيل",
}