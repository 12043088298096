export const nos_services = {
    accueil: "Nos Services",
    un_livreur: "En tant que vendeur, avez-vous des colis à livrer à Alger et les alentours ?",
    service_description: "Notre service SEND eCom vous offre la possibilité de commander un chauffeur qui se rendra à votre porte à tout moment afin de livrer vos commandes à vos clients dans un temps record. Nous proposons ce service à un prix très compétitif, vous permettant ainsi de réaliser des économies tout en bénéficiant de notre service de rapidité et de qualité.",
    advantages: "Les avantages",
    delivery_motorcycle: "livraison Par Moto",
    delivery_car: "livraison Par Voiture",
    pickup_price: "Prix de Ramassage",
    pickup_price_value: "00 DA",
    money_collection: "Recouvrement D'argent",
    delivery_mode: "Mode de livraison",
    delivery_mode_instant: "Instantané",
    delivery_time: "Délai de livraison",
    delivery_time_60: "60 Minutes",
    delivery_time_90: "90 Minutes",
    delivery_type: "Type de livraison",
    delivery_to_home: "à domicile",
    cash_on_delivery: "Paiement à la livraison",
    cash_on_delivery_available: "Disponible",
    availability: "La disponibilité",
    availability_24_7: "Valable 24h/24 et 7j/7",
    category: "Catégorie",
    category_small_packages: "Pour les colis légers et de petite taille.",
    category_large_packages: "Pour les colis plus volumineux et nécessitant l'utilisation d'un voiture.",
    unique_features: "Découvrez nos fonctionnalités uniques qui vous permettront de simplifier votre expérience de livraison et d'améliorer votre efficacité :",
    google_sheets_integration: "Améliorez votre efficacité avec l'Intégration Google Sheets !",
    google_sheets_integration_description: "Gagnez du temps et améliorez votre efficacité grâce à notre solution d'automatisation du placement des commandes. Intégrant Google Sheets, notre plateforme \"SEND\" récupère automatiquement les données nécessaires, simplifiant ainsi votre gestion et vous offrant une vision en temps réel de vos commandes. Libérez-vous des tâches manuelles et optimisez votre activité dès maintenant !",
    whatsapp_integration: "Expérience de livraison simplifiée avec l'intégration WhatsApp !",
    whatsapp_integration_description: "Restez connecté(e) à chaque étape de vos livraisons grâce à notre intégration WhatsApp. Recevez des notifications en temps réel pour la confirmation de commande, les mises à jour de livraison et les horaires estimés. Profitez d'une communication rapide et transparente pour une expérience de livraison sans souci. Simplifiez votre suivi de commandes avec notre intégration WhatsApp dès aujourd'hui !",
    call_to_action: "Alors, qu'attendez-vous ? Inscrivez-vous maintenant, commandez un chauffeur et économisez sur vos coûts de livraison.",
}