export const orders = {
    title: "Commandes",
    import_sheet: "Import Sheet",
    new_order: "Nouvelle Commande",
    order_driver: "Commande Chauffeur",
    delete: "Supprimer",
    cancel_delete: "Annuler",
    delete_order_msg: "Êtes-vous sûr de vouloir supprimer définitivement ces données?",
    order_info: "Informations sur la commande",
    search: "Rechercher",
    page: "Page",
    next: "Suivant",
    of: "De",
    previous: "Précédent",
}