export const nos_tarifs = {
    title: "Nos Tarifs",
    description: "Découvrez notre tarification transparente et équitable",
    pricing_model: "Nous utilisons un modèle de tarification basé sur la distance pour fournir aux clients des prix réels qui reflètent précisément les coûts de livraison en fonction de la distance parcourue par leur colis, ce qui en fait un système de tarification équitable et transparent.",
    advantages: "Les avantages",
    send_more_spend_less: "Envoyez plus, Dépensez moins",
    send_more_spend_less_description: "C'est une situation gagnant-gagnant ! Plus vous envoyez de commandes, moins vous dépensez. Dites adieu aux frais de livraison excessifs.",
    cost_effectiveness: "Rentabilité",
    cost_effectiveness_description: "Cela signifie que nos clients ne paient que pour la distance réelle parcourue par leur article, ce qui en fait une solution économique.",
    personalized_rates: "Tarifs personnalisés",
    personalized_rates_description: "Adaptés à vos besoins. Nous offrons des tarifs ajustables en fonction de votre volume d'envoi et de la fréquence à laquelle vous traitez des colis.",
};
