export const importSheet = {
  your_google_sheet_details: "Vos détails de feuille de calcul Google",
  sheet_url: "URL de la feuille de calcul",
  sheet_name: "Nom de la feuille de calcul",
  make_sure:
    "Assurez-vous que la structure de la feuille de calcul est celle du tableau de bord!",
  cancel: "Annuler",
  import: "Importer la feuille de calcul",
  created_successfully: "Créé avec succès!",
  something_wrong: "Quelque chose s'est mal passé!",
  fill_required_fields: "Veuillez remplir les champs obligatoires",
};