import { createI18n } from "vue-i18n";

import { accueil as frAccueil } from "./fr/accueil"
import { fields as frFields } from "./fr/fields"
import { faq as frFaq } from "./fr/faq"
import { nos_services as frNosServices } from "./fr/nos_services"
import { nos_tarifs as frNosTarifs } from "./fr/nos_tarifs"
import { intégration_api as frIntégrationApi } from "./fr/intégration_api"
import { devenir_livreur as frDevenirLivreur } from "./fr/devenir_livreur"
import { flash_messages as frFlashMessages } from "./fr/flash_messages"
import { locale as frlocale } from "./fr/locale"
import { navbar as frNavbar } from "./fr/navbar"
import {profile as frProfile} from "./fr/profile"
import {payment as frPayment} from "./fr/payment"
import { orders as frOrders } from "./fr/orders"
import { newOrder as frNewOrder } from "./fr/newOrder"
import { orderDriver as frOrderDriver } from "./fr/orderDriver"
import { importSheet as frImportSheet } from "./fr/importSheet"
import { tracking as frTracking } from "./fr/tracking";
import { history as frHistory } from "./fr/history"
import { dashboardNavBar as frDashboardNavBar } from "./fr/dashboardNavBar"


import { accueil as arAccueil } from "./ar/accueil"
import { fields as arFields } from "./ar/fields"
import { faq as arFaq } from "./ar/faq"
import { nos_services as arNosServices } from "./ar/nos_services"
import { nos_tarifs as arNosTarifs } from "./ar/nos_tarifs"
import { intégration_api as arIntégrationApi } from "./ar/intégration_api"
import { devenir_livreur as arDevenirLivreur } from "./ar/devenir_livreur"
import { flash_messages as arFlashMessages } from "./ar/flash_messages"
import { locale as arlocale } from "./ar/locale"
import { navbar as arNavbar } from "./ar/navbar"
import { orders as arOrders } from "./ar/orders"
import { profile as arProfile } from "./ar/profile"
import {payment as arPayment} from "./ar/payment"
import { newOrder as arNewOrder } from "./ar/newOrder"
import { orderDriver as arOrderDriver } from "./ar/orderDriver"
import { importSheet as arImportSheet } from "./ar/importSheet"
import { tracking as arTracking } from "./ar/tracking";
import { history as arHistory } from "./ar/history"
import { dashboardNavBar as arDashboardNavBar } from "./ar/dashboardNavBar"



import { orders as enOrders } from "./en/orders"
import { newOrder as enNewOrder } from "./en/newOrder"
import { locale as enLocale } from "./en/locale"
import { orderDriver as enOrderDriver } from "./en/orderDriver"
import { importSheet as enImportSheet } from "./en/importSheet"
import { tracking as enTracking } from "./en/tracking";
import { history as enHistory } from "./en/history"
import { dashboardNavBar as enDashboardNavBar } from "./en/dashboardNavBar"
import { profile as enProfile } from "./en/profile"
import {payment as enPayment} from "./en/payment"


const messages = {
  fr: {
    accueil: frAccueil,
    fields: frFields,
    faq: frFaq,
    nos_services: frNosServices,
    nos_tarifs: frNosTarifs,
    integration_api: frIntégrationApi,
    devenir_livreur: frDevenirLivreur,
    flash_messages: frFlashMessages,
    locale: frlocale,
    navbar: frNavbar,
    profile: frProfile,
    payment: frPayment,
    orders: frOrders,
    newOrder: frNewOrder,
    orderDriver: frOrderDriver,
    importSheet: frImportSheet,
    tracking: frTracking,
    history: frHistory,
    dashboardNavBar: frDashboardNavBar,
    
  },
  ar: {
    accueil: arAccueil,
    fields: arFields,
    faq: arFaq,
    nos_services: arNosServices,
    nos_tarifs: arNosTarifs,
    integration_api: arIntégrationApi,
    devenir_livreur: arDevenirLivreur,
    flash_messages: arFlashMessages,
    locale: arlocale,
    navbar: arNavbar,
    orders: arOrders,
    newOrder: arNewOrder,
    orderDriver: arOrderDriver,
    importSheet: arImportSheet,
    tracking: arTracking,
    history: arHistory,
    dashboardNavBar: arDashboardNavBar,
    profile: arProfile,
    payment: arPayment,
  },
  en: {
    orders: enOrders,
    newOrder: enNewOrder,
    orderDriver: enOrderDriver,
    locale: enLocale,
    importSheet: enImportSheet,
    tracking: enTracking,
    history: enHistory,
    dashboardNavBar: enDashboardNavBar,
    profile: enProfile,
    payment: enPayment,
  },
};


export default createI18n({
    locale: localStorage.getItem("user-locale") || "fr",
    fallbackLocale: "ar",
    globalInjection: true,
    legacy: false,
    formatFallbackMessages: true,
    messages
})