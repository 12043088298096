export const accueil = {
    accueil: "Accueil",
    un_livreur: "Un livreur",
    en_click: "en 1 clic",
    header_description : "Vivez une expérience de livraison sur mesure en un seul clic, avec des chauffeurs disponibles à tout moment et n'importe où à Alger.",
    suivez_votre_commande_en_temps_réel : "Suivez votre commande en temps réel",
    saisissez_le_numéro_de_suivi : "Saisissez le numéro de suivi ",
    qui_nous_sommes: "Qui nous sommes",
    qui_nous_sommes_description: "Nous sommes une entreprise algérienne, qui propose des solutions innovantes dans la livraison du dernier kilomètre. Notre mission est de faciliter la mise en relation entre les personnes à la recherche d'un service de livraison et les chauffeurs disponibles.",
    pourquoi_nous: "Pourquoi Nous ?",
    ultra_rapide: "Ultra-rapide",
    processus_de_livraison : "Simplifiez votre processus de livraison en choisissant notre service de livraison directe. Nous éliminons le tri et le stockage, ce qui signifie que vous n'aurez plus à vous soucier de ces étapes. Nos chauffeurs récupèrent les articles chez vous et les livrent directement à vos clients. En réduisant les manipulations, nous garantissons une livraison rapide à vos destinataires. Optez pour la simplicité et la rapidité avec notre service de confiance !",
    instantanée: "Instantanée",
    livraison_personnalisée: "Profitez d'une livraison personnalisée à Alger en un clic ! Des chauffeurs disponibles à tout moment, partout dans la ville, sont prêts à récupérer et livrer vos articles rapidement. Ne vous souciez plus des délais, notre service répond à vos besoins urgents.",
    moins_chère: "Moins chère",
    rentabilité_pour_votre_entreprise : "Nous comprenons l'importance de la rentabilité pour votre entreprise. C'est pourquoi nous proposons des tarifs compétitifs et abordables pour nos services de livraison. En optimisant notre réseau de chauffeurs et en utilisant une tarification basée sur la distance, nous vous offrons des solutions de livraison économiques sans compromettre la qualité. Réduisez vos coûts de livraison tout en assurant la satisfaction de vos clients grâce à notre service abordable et fiable."
}