export const importSheet = {
  your_google_sheet_details: "تفاصيل جدول جوجل الخاص بك",
  sheet_url: "رابط الجدول",
  sheet_name: "اسم الجدول",
  make_sure: "تأكد من أن هيكل الجدول مطابق لما هو موجود في لوحة التحكم!",
  cancel: "إلغاء",
  import: "استيراد الجدول",
  created_successfully: "تم الإنشاء بنجاح!",
  something_wrong: "حدث خطأ ما!",
  fill_required_fields: "يرجى ملء الحقول المطلوبة",
};