
import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      meta: {
        requiresAuth: false,
      },

      path: "/",
      name: "home",
      component: () => import("@/views/LandingView.vue"),
    },
    {
      meta: {
        requiresAuth: false,
      },
      path: "/our-services",
      name: "our-services",
      component: () => import("@/views/landing/OurServices.vue"),
    },
    {
      meta: {
        requiresAuth: false,
      },
      path: "/marques",
      name: "marques",
      component: () => import("@/views/landing/Marques.vue"),
    },

    {
      path: "/become-delivery",
      name: "become-delivery",
      component: () => import("@/views/landing/BecomeDelivery.vue"),
    },

    {
      meta: {
        requiresAuth: false,
      },
      path: "/integration-api",
      name: "integration-api",
      component: () => import("@/views/landing/IntegrationAPI.vue"),
    },

    {
      meta: {
        requiresAuth: false,
      },
      path: "/nous-tarifs",
      name: "nous-tarifs",
      component: () => import("@/views/landing/NosTarifs.vue"),
    },

    {
      meta: {
        requiresAuth: false,
      },
      path: "/livraison",
      name: "livraison",
      component: () => import("@/views/landing/Livraison.vue"),
    },

    {
      meta: {
        requiresAuth: false,
      },
      path: "/steps",
      name: "steps",
      component: () => import("@/views/landing/StepsForm.vue"),
    },
    {
      meta: {
        requiresAuth: false,
      },
      path: "/email-verification",
      name: "email-verification",
      component: () => import("@/views/auth/EmailVerification.vue"),
    },
    {
      meta: {
        requiresAuth: false,
      },
      path: "/forget-password",
      name: "forget-password",
      component: () => import("@/views/auth/ForgetPassword.vue"),
    },
    {
      meta: {
        requiresAuth: false,
      },
      path: "/inscription",
      name: "inscription",
      component: () => import("@/views/auth/Inscription.vue"),
    },

    {
      meta: {
        requiresAuth: false,
      },
      path: "/login/:token",
      name: "login-token",
      component: () => import("@/views/auth/Login.vue"),
    },

    {
      meta: {
        requiresAuth: false,
      },
      path: "/login",
      name: "login",
      component: () => import("@/views/auth/Login.vue"),
    },

    {
      meta: {
        requiresAuth: false,
      },
      path: "/login/google",
      name: "login-google",
      component: () =>
        (window.location.href = "https://api.send-dz.com/login/google/seller"),
    },

    {
      path: "/orders",
      name: "orders",
      component: () => import("@/views/OrdersView.vue"),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "new",
          name: "orders.new",
          component: () => import("@/components/form/NewOrderForm.vue"),
        },
        {
          path: "orders-driver",
          name: "orders.driver",
          component: () => import("@/components/form/OrderDriverForm.vue"),
        },
        {
          path: "edit/:id",
          name: "orders.edit",
          component: () => import("@/components/form/EditOrderForm.vue"),
        },
        {
          path: "print/:id",
          name: "orders.print",
          component: () => import("@/components/PrintableRowData.vue"),
        },
      ],
    },

    {
      path: "/tracking",
      name: "tracking",
      component: () => import("@/views/TrackingView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("@/views/ProfileView.vue"),
      meta: {
        requiresAuth: true,
      },
      children: [],
    },
    {
      path: "/profile/edit",
      name: "profile.edit",
      component: () => import("@/views/EditProfileView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/history",
      name: "history",
      component: () => import("@/views/HistoryView.vue"),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "/print/:id",
          name: "history.print",
          component: () => import("@/components/PrintableRowDataH.vue"),
        },
      ],
    },
    {
      path: "/payment",
      name: "payment",
      component: () => import("@/views/PaymentView.vue"),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "/print/:id",
          name: "payment.print",
          component: () => import("@/components/PrintableRowDataP.vue"),
        },
      ],
    },
  ],
});
import { useAuthStore } from "@/stores/auth.js";

router.beforeEach((to, from, next) => {
  const store = useAuthStore();
  if (!store.token && to.meta.requiresAuth) {
    next({ name: "login" });
  } else {
    next();
  }
});

export default router;

//////////////////////
