export const newOrder = {
  client: "Client",
  client_placeholder: "enter client name",
  phone: "Phone",
  phone_placeholder: "enter client phone",
  address: "Delivery address",
  address_placeholder: "enter delivery address",
  product: "Product",
  product_placeholder: "enter product name",
  delivery_cost: "Delivery cost",
  delivery_cost_placeholder: "enter delivery cost",
  total_cost: "Total Cost",
  total_cost_placeholder: "enter total cost",
  payment_type: "Payment type",
  cash: "Cash",
  e_transfer: "E-Transfer",
  notes: "Notes",
  notes_placeholder: "enter notes",
  create_order: "Create Order",
  edit_order: "Edit Order",
  cancel: "Cancel",

  //notifications
  created_successfully: "Created Successfully!",
  modified_successfully: "Modified Successfully!",
  something_wrong: "Something went wrong!",
};