
export const payment = {
    title: 'الدفع',
    credit: 'رصيدي',
    currency: 'دينار جزائري',
    bonus: 'مكافأة',
    kilometers: 'كيلومتر',
    order_info: 'معلومات الطلب',
};


