export const newOrder = {
  client: "Client",
  client_placeholder: "entrer le nom du client",
  phone: "Téléphone",
  phone_placeholder: "entrer le numéro de téléphone du client",
  address: "Adresse de livraison",
  address_placeholder: "entrer l'adresse de livraison",
  product: "Produit",
  product_placeholder: "entrer le nom du produit",
  delivery_cost: "Frais de livraison",
  delivery_cost_placeholder: "entrer les frais de livraison",
  total_cost: "Coût total",
  total_cost_placeholder: "entrer le coût total",
  payment_type: "Type de paiement",
  cash: "Espèces",
  e_transfer: "Virement électronique",
  notes: "Remarques",
  notes_placeholder: "entrer des remarques",
  create_order: "Créer une commande",
  edit_order: "Modifier la commande",
  cancel: "Annuler",

  //notificationsa
  created_successfully: "Créé avec succès!",
  modified_successfully: "Modifié avec succès!",
  something_wrong: "Quelque chose s'est mal passé!",
};
