export const history = {
  title: "history",
  total_orders: "Total number of orders",
  vs_last_month: "vs last month",
  total_delivered: "Total number of delivered orders",
  delivered: "delivered",
  delivered_desc:
    "means the order has been successfully delivered to the customer.",
  pending: "pending",
  pending_desc:
    "means the order has been placed successfully and is waiting for the seller to accept it.",
  cancelled: "canceled",
  cancelled_desc: "means the order has been rejected by the client .",
  returned: "returned",
  returned_desc: "means the order has been returned to the seller.",
  order_info: "Order information",
};