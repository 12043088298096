export const orders = {
  title: "الطلبيات",
  import_sheet: "execl استيراد ملف ",
  new_order: "طلب جديد",
  order_driver: 'طلب توصيل',
  delete: "حذف",
  cancel_delete: "إلغاء",
  delete_order_msg: "هل أنت متأكد أنك تريد حذف هذه البيانات بشكل دائم؟",
  search: "بحث",
  page: "صفحة",
  next: "التالي",
  of: "من",
  previous: "السابق",

  //printable data
  
  order_info: "معلومات الطلب",
  order_number: ":رقم الطلب",
  client: ":العميل",
  phone: ":الهاتف",
  address: ":عنوان التسليم",
  product: ":المنتج",
  date: ":التاريخ",
  tracking_number: ":الرقم التسلسلي",
  quantity: ":طريقة الدفع",
  delivery_cost: ":تكلفة التوصيل",
};