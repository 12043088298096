export const tracking = {
  title: "Tracking",
  search: "Chercher",
  search_placeholder: "Entrez le numéro de suivi",

  //order info
  shop_name: "Nom du magasin",
  phone_number: "Numéro de téléphone",
  client_name: "Nom du client",
  driver_name: "Nom du chauffeur",
  product_name: "Nom du produit",
  total_price: "Prix total",
  pickup_location: "Lieu de ramassage",
  address: "Adresse",
  delivery_price: "Frais de livraison",
  delivery_date: "Date de livraison",
  status_delivery: "Statut de livraison",
  status_payment: "Statut de paiement",
  pickup_address: "Adresse de ramassage",
  delivery_status: "Statut de livraison",
  payment_status: "Statut de paiement",
  delivery_address: "Adresse de livraison",
};
